import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { showErrorToast } from 'components/common/Toaster';
import { BAD_REQUEST, SUCCESS } from 'constants/apiStatusCodes';
import { getError } from 'constants/common';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { getAutocompleteList } from 'redux/APICaller';
import styles from './styles';

const DynamicAutocomplete = ({
  label,
  name,
  control,
  errors,
  fullWidth = true,
  variant = 'standard',
  rules,
  placeholder = 'Start Typing...',
  searchKey = 'oms_code',
  customStyles,
  path,
  customPayload = {},
  optionRenderer,
  isPoDetailsFetched,
  setValue,
  setIsPoDetailsFetched
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const error = getError(name, errors);
  const [casheResult, setCasheResult] = useState({});

  const handleInputChange = async (e) => {
    const inputValue = e?.target?.value;

    if (inputValue?.length >= 3) {
      setFilteredOptions([]);
      setIsLoading(true);
      if (!casheResult[inputValue]) {
        const updatedPayload = { ...customPayload, [searchKey]: inputValue };
        const res = await getAutocompleteList(updatedPayload, path);
        if (res?.status == SUCCESS) {
          setFilteredOptions(optionRenderer(res?.data));
          setCasheResult({ ...casheResult, [inputValue]: optionRenderer(res?.data) });
        } else {
          setFilteredOptions([]);
          if (res?.status === BAD_REQUEST) showErrorToast(res?.data?.message);
          else showErrorToast();
        }
      } else {
        setFilteredOptions(casheResult[inputValue]);
      }
      setIsLoading(false);
    } else {
      setFilteredOptions([]);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <FormControl
            sx={{ ...styles.wrapper, ...customStyles }}
            fullWidth={fullWidth}
            variant={variant}>
            <Autocomplete
              {...field}
              options={filteredOptions}
              getOptionDisabled={(option) => option?.disabled}
              fullWidth={fullWidth}
              value={field?.value}
              onChange={(e, newValue) => {
                e.target.value = '';
                if (typeof newValue === 'object' && newValue !== null) field?.onChange(newValue);
              }}
              freeSolo={!searchStr || searchStr?.length < 3 || isLoading}
              renderInput={(params) => (
                <Box ref={params?.InputProps?.ref}>
                  {label && <InputLabel shrink>{label}</InputLabel>}
                  <InputBase
                    _id={name}
                    inputProps={params.inputProps}
                    fullWidth={fullWidth}
                    onBlur={(e) => {
                      e.target.value = '';
                      field?.onBlur;
                      setSearchStr('');
                      setFilteredOptions([]);
                    }}
                    sx={styles.input}
                    error={!!error}
                    placeholder={placeholder}
                    onChange={(e) => {
                      handleInputChange(e);
                      if (searchKey === 'po_number' && isPoDetailsFetched) {
                        setValue('brand', '');
                        setValue('shipFromWarehouse', '');
                        setValue('shipToWarehouse', '');
                        setValue('locationCode', '');
                        setValue('channel', '');
                        setValue('category', '');
                        setIsPoDetailsFetched(false);
                      }
                    }}
                  />
                  {isLoading && <CircularProgress size={20} sx={styles.progressBar} />}
                </Box>
              )}
            />
            {error && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default DynamicAutocomplete;
