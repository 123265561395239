import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';

const POIdRenderer = (cellData, _id = '') => {
  const navigate = useNavigate();

  const ACN_ID = cellData?.ACNId ? cellData?.ACNId : cellData;

  const rowIdClick = (id) => {
    navigate(`/view-orders/${id}`);
  };

  return (
    <Box
      _id={_id}
      sx={styles.lcanId}
      component="a"
      href={`/view-orders/${ACN_ID}`}
      target="_blank"
      // onClick={() => rowIdClick(ACN_ID)}
    >
      {ACN_ID}
    </Box>
  );
};

export default POIdRenderer;
